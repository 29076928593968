body {
  margin: 0;
  background-image: url(bg.jpg);
  background-size: cover;
  background-position: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  position:relative
}

.App{
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  max-width: 800px;
  background-color: rgba(255, 255, 255, 0.945);
}

.timelineContainer {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-areas: "date entry";
  gap: 2rem;
}

.date {
  grid-area: date;
  text-align: right;
  color: grey;
}

.content {
  text-align: left;
  padding-left: 3rem;
}

.dot {
  border: 2px solid grey;
  height: 1.25rem;
  width:  1.25rem;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  margin-left: -0.8rem;
  margin-top: 1.25rem;
}

.line{
  border-left: 2px solid grey;
  margin-left: 1rem;
}

.projectImage {
  margin: 10px;
  border: 2px solid grey;
  max-height: 75vh;
  overflow: hidden;
}

#slideContainer {
  overflow: hidden;
  display: grid;
  grid-template-columns: 100% 100% 100%;
  grid-template-areas: "A B C";
  min-height: 100vh;
}

#slideA {
  grid-area: A;
}

#slideB {
  grid-area: B;
}

#slideC {
  grid-area: C;
}

.arrow {
  border: solid #0097ce7b;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  position: relative;
  z-index: 1;
}

.arrow:hover {
  border-color: #0096CE;
  cursor: pointer;
}
.right {  
  transform: scale(5,6) rotate(-45deg);
  top: 40vh;
  left: 43%;
}

.left {
  transform: scale(5,6) rotate(135deg);
  top: 40vh;
  right: 43%;
}

h1 {
  color: #0096CE;
  display: inline-block;
  padding: 5px;
}

p {
  padding-left: 20px;
  padding-right: 20px;
}

.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  background-color: #0097ce7b;
}

.row {
  display: grid;
  grid-template-columns: repeat(
      auto-fill,
      minmax(220px, 1fr)
  );
  grid-gap: 20px;

  @media (max-width: 1000px) {
      grid-template-columns: repeat(
          auto-fill,
          minmax(300px, 1fr)
      );
  }
}

.footerLink {
  color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  text-decoration: none;

  &:hover {
      color: #0096CE;
      transition: 200ms ease-in;
  }
}

#pfp {
  width: 150px;
}

.talk-bubble {
  color: black;
	margin: 40px;
  display: inline-block;
  position: relative;
	width: 200px;
	height: auto;
	background-color: lightyellow;
}

.tri-right.border.btm-left-in:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 51px;
  right: auto;
  top: auto;
	bottom: -40px;
	border: 20px solid;
	border-color: #666 transparent transparent #666;
}
.tri-right.btm-left-in:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
	left: 55px;
  right: auto;
  top: auto;
	bottom: -28px;
	border: 15px solid;
	border-color: lightyellow transparent transparent lightyellow;
}

.border{
  border: 5px solid #666;
}
.round{
  border-radius: 30px;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;

}

#bubble {
  position:absolute; 
  bottom:0;
}